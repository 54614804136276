import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import Blog from "./pages/Blog";
import About from "./pages/About";
import Snapshot from "./pages/Snapshot";
import Skills from "./pages/Skills";
import Projects from "./pages/Projects";
function Router() {
    return (
        < BrowserRouter >
            <Routes>
                <Route path="/" element={< App />} />
                <Route path="/blog" element={< Blog />} />
                <Route path="/about" element={< About />} />
                <Route path="/snapshot" element={< Snapshot />} />
                <Route path="/my-skills" element={< Skills />} />
                <Route path="/projects" element={< Projects />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;