import LogoSmall from '../assets/svg/LogoSmall';
import { Link } from "react-router-dom";
const Header = (props) => (
    <nav className="navbar navbar-expand-lg fixed-top bg-white navbar-light">
        <div className="container">
            <a className="navbar-brand ff-yeseva" href="./">
                <LogoSmall id="logo-small" className="the-agunda-hover" width="100" height="60"></LogoSmall>
            </a>
            <div className="d-flex ms-auto order-lg-1">
                {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#topNavbar" aria-controls="topNavbar" aria-expanded="false" aria-label="Toggle navigation">
              <i className="bi bi-three-dots-vertical"></i>
            </button>
            <button className="btn shadow-no position-relative center-both text-primary" id="off-toggler" type="button" aria-expanded="false" aria-label="Toggle navigation" data-tor="click:className.toggle(active,{target:body})" data-tor-parent="hover hover hover hover hover hover">
              <i className="fa fa-list h4 mb-0 z-index-1 center-both"></i> 
              <i className="fa fa-arrow-left position-absolute d-flex" data-tor="hover(#off-toggler):reveal(left) delay(100ms) quad"></i>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" data-tor-group="path=>hover(#off-toggler):scaleX.to(0) originX(0%) quad delay(/+100ms/);">
                <path fill="currentColor" d="M2.5 4c0-.3.2-.5.5-.5h10c.3 0 .5.2.5.5s-.2.5-.5.5H3c-.3 0-.5-.2-.5-.5z" data-tor="hover(#off-toggler):scaleX.to(0) originX(0%) quad delay(100ms)"></path>
                <path fill="currentColor" d="M2.5 8c0-.3.2-.5.5-.5h10c.3 0 .5.2.5.5s-.2.5-.5.5H3c-.3 0-.5-.2-.5-.5z" data-tor="hover(#off-toggler):scaleX.to(0) originX(0%) quad delay(200ms)"></path>
                <path fill="currentColor" d="M2.5 12c0-.3.2-.5.5-.5h10c.3 0 .5.2.5.5s-.2.5-.5.5H3c-.3 0-.5-.2-.5-.5z" data-tor="hover(#off-toggler):scaleX.to(0) originX(0%) quad delay(300ms)"></path>
              </svg>
              <span className="circle circle-md position-absolute bg-opacity-10" data-tor="hover(#off-toggler):bg(dark) hover(#off-toggler):scale.from(0)"></span>
            </button> */}
            </div>
            <div className="collapse navbar-collapse small order-lg-0" id="headerTopbar">
                {/* <ul className="navbar-nav d-flex w-100 justify-content-evenly px-2 px-lg-7 links-dark"> */}
                <ul className="navbar-nav d-flex w-100 justify-content-end px-2 px-lg-7 links-dark">
                    {/* <li className="nav-item">
                <a className="nav-link" href="#">Call me (+778) 675-0765</a>
              </li>*/}
                    <li className="nav-item">
                        <Link to={'/about'} className="nav-link">
                            <i className="fa-solid fa-exclamation-circle header-icon"></i>
                            {/* About */}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/blog'} className="nav-link">
                            <i className="fa-solid fa-blog header-icon"></i>
                            {/* Blog */}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/'} className="nav-link">
                            <i className="fa-solid fa-home header-icon"></i>
                            {/* Blog */}
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
);
export default Header;