import { lazy } from "react";
import { Toaster } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import "./assets/fontawesome/fontawesome-free-6.1.2-web/css/all.min.css"
import './App.css';
const HomeSection = lazy(() => import('./components/HomeSection'));
const ServicesSection = lazy(() => import('./components/ServicesSection'));
const StayConnected = lazy(() => import('./components/StayConnected'));
const ContactSection = lazy(() => import('./components/ContactSection'));
const WorkProcessSection = lazy(() => import('./components/WorkProcessSection'));
const MyWeaponsSection = lazy(() => import('./components/MyWeaponsSection'));
const SkillsSection = lazy(() => import('./components/SkillsSection'));
const ExperienceSection = lazy(() => import('./components/ExperienceSection'));
const PortfolioSection = lazy(() => import('./components/PortfolioSection'));
const TestimonialsSection = lazy(() => import('./components/TestimonialsSection'));
const Header = lazy(() => import('./components/Header'));
// import HomeSection from "./components/HomeSection";
// import ServicesSection from './components/ServicesSection';
// import LogoSmall from './assets/svg/LogoSmall';
// import StayConnected from './components/StayConnected';
// import ContactSection from './components/ContactSection';
// import WorkProcessSection from './components/WorkProcessSection';
// import MyWeaponsSection from './components/MyWeaponsSection';
// import SkillsSection from './components/SkillsSection';
// import ExperienceSection from './components/ExperienceSection';
// import PortfolioSection from './components/PortfolioSection';
// import TestimonialsSection from './components/TestimonialsSection';
// import Header from "./components/Header";
function App() {
  return (
    <>
      <Header></Header>
      <main id="main-content" className='pt-2'>
        <Toaster position="top-right" />
        <HomeSection></HomeSection>
        <ServicesSection></ServicesSection>
        <SkillsSection></SkillsSection>
        <ExperienceSection></ExperienceSection>
        <PortfolioSection></PortfolioSection>
        <TestimonialsSection></TestimonialsSection>
        <MyWeaponsSection></MyWeaponsSection>
        <WorkProcessSection></WorkProcessSection>
        <ContactSection></ContactSection>
        <StayConnected></StayConnected>
      </main>
    </>
  );
}

export default App;
