import Header from "../components/Header";
import React, { useEffect } from "react";
import style from "../assets/css/snapshot/Snapshot.css";
import '../App.css';
import ImageCard from "../components/snapshot/ImageCard";
import { useState } from "react";
import axios from "axios";
import { render } from "@testing-library/react";


class Snapshot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            images: [],
            tags: [],
            is_loading: false,
        };
    }
    onSearch = () => {
        this.setState({
            is_loading: true
        });
        axios.get(`http://127.0.0.1:8000/search/${this.state.search}`, {
            method: "GET",
        }).then((response) => {
            let images = response.data.images;
            let tags = response.data.tags;
            this.setState({ images: images, tags: tags, search: "" });
        }).finally(() => {
            this.setState({
                is_loading: false
            });
        })
    }
    onTagClick = (tag) => {
        this.setState({
            search: tag
        });
        setTimeout(() => {
            this.onSearch();
        }, 20)
    }

    render() {
        return (
            <>
                <Header></Header>
                <main id="main-content" className='pt-2'>
                    <section id="home">
                        <div className="container">
                            <div className="row row-cols-1">
                                <div className="col my-3">
                                    <div className="title-badge d-inline-block mb-6 bg-primary text-primary bg-opacity-10 px-3 small">
                                        Snapshot
                                    </div>
                                    <div className="text-center">
                                        <h1 className="">
                                            <span className="the-agunda-hover ff-billabong bold">SnapShot</span>
                                        </h1>
                                        <h2 className="h3 fw-normal fs-normal text-body-light the-agunda-hover">
                                            Community
                                        </h2>
                                        <p className="text-body-light w-80 mb-3 small">
                                            Hey Just Wait we will come back to you
                                        </p>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-6">
                                            <div className="snapchat search-wrapper  showdow-sm">
                                                <div className="search-icon">
                                                    <i className="fa fa-regular fa-image"></i>
                                                </div>
                                                <input type="search" className="search-input" placeholder="Search" onChange={(event) => this.setState({ search: event.target.value })} value={this.state.search} />
                                                <button type="button" className="search-btn" onClick={this.onSearch}>
                                                    <i className="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="">
                        <div className="container"> 
                            {/* No Images Found Please try a different search term */}
                            <div className="d-flex justify-content-center mb-3">
                                {this.state.tags.map((tag, index) => {
                                    return (
                                        <span className="badge me-2 ms-2 text-bg-dark showdow-sm" key={tag} onClick={(event) => { this.onTagClick(tag) }}>
                                            {tag}
                                        </span>
                                    )
                                })}
                            </div>
                            {this.state.is_loading ?
                                <>
                                    <div className="d-flex justify-content-center mb-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{ 'margin': 'auto', 'background': 'none', 'display': 'block', 'shape-rendering': 'auto' }} width="74px" height="74px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                            <g transform="rotate(0 50 50)">
                                                <rect x="45.5" y="9.5" rx="4.5" ry="7.5" width="9" height="15" fill="#ffdb7c">
                                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                                                </rect>
                                            </g><g transform="rotate(30 50 50)">
                                                <rect x="45.5" y="9.5" rx="4.5" ry="7.5" width="9" height="15" fill="#ffdb7c">
                                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                                                </rect>
                                            </g><g transform="rotate(60 50 50)">
                                                <rect x="45.5" y="9.5" rx="4.5" ry="7.5" width="9" height="15" fill="#ffdb7c">
                                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                                                </rect>
                                            </g><g transform="rotate(90 50 50)">
                                                <rect x="45.5" y="9.5" rx="4.5" ry="7.5" width="9" height="15" fill="#ffdb7c">
                                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                                                </rect>
                                            </g><g transform="rotate(120 50 50)">
                                                <rect x="45.5" y="9.5" rx="4.5" ry="7.5" width="9" height="15" fill="#ffdb7c">
                                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                                                </rect>
                                            </g><g transform="rotate(150 50 50)">
                                                <rect x="45.5" y="9.5" rx="4.5" ry="7.5" width="9" height="15" fill="#ffdb7c">
                                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                                                </rect>
                                            </g><g transform="rotate(180 50 50)">
                                                <rect x="45.5" y="9.5" rx="4.5" ry="7.5" width="9" height="15" fill="#ffdb7c">
                                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                                                </rect>
                                            </g><g transform="rotate(210 50 50)">
                                                <rect x="45.5" y="9.5" rx="4.5" ry="7.5" width="9" height="15" fill="#ffdb7c">
                                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                                                </rect>
                                            </g><g transform="rotate(240 50 50)">
                                                <rect x="45.5" y="9.5" rx="4.5" ry="7.5" width="9" height="15" fill="#ffdb7c">
                                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                                                </rect>
                                            </g><g transform="rotate(270 50 50)">
                                                <rect x="45.5" y="9.5" rx="4.5" ry="7.5" width="9" height="15" fill="#ffdb7c">
                                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                                                </rect>
                                            </g><g transform="rotate(300 50 50)">
                                                <rect x="45.5" y="9.5" rx="4.5" ry="7.5" width="9" height="15" fill="#ffdb7c">
                                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                                                </rect>
                                            </g><g transform="rotate(330 50 50)">
                                                <rect x="45.5" y="9.5" rx="4.5" ry="7.5" width="9" height="15" fill="#ffdb7c">
                                                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                                                </rect>
                                            </g>
                                        </svg>
                                    </div>
                                </>
                                : null}


                            <div className="card-columns img-row-wrapper">
                                {this.state.images.map((src, index) => {
                                    return (
                                        < div className="card" key={src}>
                                            <div className="card-btn-wrapper">
                                                <button className="btn">
                                                    <i className="fa fa-download"></i>
                                                </button>
                                            </div>
                                            <ImageCard src={src} width={'100%'} className="col-img showdow-sm" />
                                        </div>
                                    )
                                })}
                            </div>
                            {/* <nav>
                                <ul className="pagination pagination-sm">
                                    <li className="page-item active" aria-current="page">
                                        <span className="page-link">1</span>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                </ul>
                            </nav> */}
                        </div>
                    </section>
                </main>
            </>
        )
    }

}


// function Snapshot(props) {
//     const [formInput, setFormInput] = useState({
//         search: '',
//         images: [],
//         tags: [],
//     })
//     useEffect(() => {
//     }, [formInput]);

//     const onSearchChange = (event) => {
//         console.log(event)
//         let data;
//         axios.get(`http://127.0.0.1:8000/search/${formInput.search}`, {
//             method: "GET",
//         }).then((response) => {
//             data = response.data.images;
//             console.log(response.data)
//             setFormInput({ images: data, search: "" });
//         }).finally(() => {
//             console.log(data);
//         })
//     }
//     return (
//         <>
//             <Header></Header>
//             <main id="main-content" className='pt-2'>
//                 <section id="home">
//                     <div className="container">
//                         <div className="row row-cols-1">
//                             <div className="col my-3">
//                                 <div className="title-badge d-inline-block mb-6 bg-primary text-primary bg-opacity-10 px-3 small">
//                                     Snapshot
//                                 </div>
//                                 <div className="text-center">
//                                     <h1 className="">
//                                         <span className="the-agunda-hover ff-billabong bold">SnapShot</span>
//                                     </h1>
//                                     <h2 className="h3 fw-normal fs-normal text-body-light the-agunda-hover">
//                                         Community
//                                     </h2>
//                                     <p className="text-body-light w-80 mb-3 small">
//                                         Hey Just Wait we will come back to you
//                                     </p>
//                                 </div>
//                                 <div className="row justify-content-center">
//                                     <div className="col-md-6">
//                                         <div className="snapchat search-wrapper  showdow-sm">
//                                             <div className="search-icon">
//                                                 <i className="fa fa-regular fa-image"></i>
//                                             </div>
//                                             <input type="search" className="search-input" placeholder="Search" onChange={(event) => setFormInput({ ...formInput, search: event.target.value })} value={formInput.search} />
//                                             <button type="button" className="search-btn" onClick={onSearchChange}>
//                                                 <i className="fa fa-search"></i>
//                                             </button>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//                 <section className="">

//                     {/* No Images Found
//                             Please try a different search term */}
//                     <div className="container ">
//                         <div className="d-flex justify-content-center mb-3">
//                             <span className="badge me-2 ms-2 text-bg-dark showdow-sm">Dark</span>
//                             <span className="badge me-2 ms-2 text-bg-dark showdow-sm">Dark</span>
//                             <span className="badge me-2 ms-2 text-bg-dark showdow-sm">Dark</span>
//                             <span className="badge me-2 ms-2 text-bg-dark showdow-sm">Dark</span>
//                             <span className="badge me-2 ms-2 text-bg-dark showdow-sm">Dark</span>
//                         </div>
//                         <div className="row img-row-wrapper">

//                             {formInput?.images.map((src, index) => {
//                                 return (
//                                     < div className="col-lg-3 mb-lg-3 mb-sm-2" key={src} >
//                                         <ImageCard src={src} />
//                                     </div>
//                                 )
//                             })}
//                         </div>
//                         <nav aria-label="">
//                             <ul className="pagination pagination-sm">
//                                 <li className="page-item active" aria-current="page">
//                                     <span className="page-link">1</span>
//                                 </li>
//                                 <li className="page-item"><a className="page-link" href="#">2</a></li>
//                                 <li className="page-item"><a className="page-link" href="#">3</a></li>
//                             </ul>
//                         </nav>
//                     </div>
//                 </section>
//             </main>
//         </>
//     );
// }
export default Snapshot;
