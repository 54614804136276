import Header from "../components/Header";
import { useEffect, useState } from "react";
import '../App.css';
import MainHero from "../assets/svg/MainHero";
import Card from "../components/layouts/Card";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Helmet } from "react-helmet";
function Projects() {
    const [projects, setProjects] = useState([]);
    const [showCanvas, setShowCanvas] = useState(false);
    /**
     * Single state....
     */
    const [project, setProject] = useState({})
    const handleClose = () => setShowCanvas(false);
    useEffect(() => {
        fetch(`${window.location.origin}/assets/projects.json`)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then((response) => {
                setProjects(response);
            }).catch((error) => {
                console.log(error);
            });
    }, []);
    const handleClick = (data) => {
        setProject(data);
        setShowCanvas(true);
    }
    return (
        <>
            <Helmet>
                <title>Projects | Kiran Badola</title>
            </Helmet>
            <Header></Header>
            <main id="main-content" className='pt-2'>
                <section id="home">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-md-2 center-v">
                            <div className="col my-3">
                                <div className="title-badge d-inline-block mb-6 bg-primary text-primary bg-opacity-10 px-3 small">
                                    Projects
                                </div>
                                <h1 className="mb-4">
                                    <span className="small">Hello World,</span><br />
                                    <span>I'm </span>
                                    <span className="text-primary the-agunda-hover">Kiran Badola</span>
                                </h1>
                                <h2 className="h3 fw-normal fs-normal text-body-light mb-3 the-agunda-hover">Full Stack Developer</h2>
                                <p className="text-body-light w-80 mb-2">
                                    As a developer I have been working few years in this sector, and I have completed lot's of <span className="text-primary the-agunda-hover">projects.</span>
                                </p>
                                <p className="text-body-light w-80 mb-6">
                                    I have a vast variety of skills in Front-end engineering, Backend Development, AWS & Deployment.
                                </p>
                            </div>
                            <div className="col d-flex justify-content-center">
                                <MainHero></MainHero>
                            </div>
                        </div>
                        <div className="row row-cols-1 center-v mb-5 mt-5">
                            <Offcanvas show={showCanvas} onHide={handleClose}>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>
                                        Project Details
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <div className="canvas-title">
                                        {project.name}
                                    </div>
                                    <div className="canvas-text">
                                        <p> {project.description}</p>
                                    </div>
                                    <div className="canvas-image-wrap">
                                        <img src={project.image} className="img-fluid"></img>
                                    </div>
                                    <div className="canvas-text">
                                        <h4>About</h4>
                                        <p> {project.about}</p>

                                        <h4>Technologies</h4>
                                        <p>{project.technologies}</p>

                                        <h4> <i className="fa fa-globe-asia me-1"></i>Website</h4>
                                        {project.website}
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                            <div className="card-columns">
                                {
                                    projects && projects.map((data) => {
                                        return <Card data={data} image={data.image} name={data.name} description={data.description} technologies={data.technologies} key={data.id} onClick={handleClick} />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Projects;
