import Header from "../components/Header";
import { useEffect } from "react";
import '../App.css';
import MainHero from "../assets/svg/MainHero";
import { Helmet } from "react-helmet";
function About() {
    return (
        <>
            <Helmet>
                <title>About Me | Kiran Badola</title>
            </Helmet>
            <Header></Header>
            <main id="main-content" >
                <section id="home">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-md-2 center-v min-vh-100">
                            <div className="col my-3">
                                <div className="title-badge d-inline-block mb-6 bg-primary text-primary bg-opacity-10 px-3 small">About me</div>
                                <h1 className="mb-4">
                                    <span className="small">Hello World,</span>
                                    <br />
                                    <span>I'm </span>
                                    <span className="text-primary the-agunda-hover">Kiran Badola</span>
                                </h1>
                                <h2 className="h3 fw-normal fs-normal text-body-light mb-3 the-agunda-hover">Full Stack Developer</h2>
                                <p className="text-body-light w-80 mb-6">
                                    I'm a 26 year old programmer living in Dehradun, UK, India. <span className="text-primary the-agunda-hover">build websites</span> that looks great, feels fantastic, and functions correctly.
                                </p>
                                <button className="btn btn-primary btn-arrow py-2 px-3 mb-4 the-agunda-hover">Hire me</button>
                                <div className="social-icons ">
                                    <a href="https://www.instagram.com/kiran.badola.50/" className="d-inline-block me-3" target={"_blank"}>
                                        <i className="fab fa-instagram the-agunda-hover"></i>
                                    </a>
                                    <a href="https://www.facebook.com/kiran.badola.50/" className="d-inline-block me-3" target={"_blank"}>
                                        <i className="fab fa-facebook-f the-agunda-hover"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/in/kiran-badola-kb09081996" className="d-inline-block me-3" target={"_blank"}>
                                        <i className="fab fa-linkedin the-agunda-hover"></i>
                                    </a>
                                    <a href="https://github.com/TheAgunda" className="d-inline-block me-3" target={"_blank"}>
                                        <i className="fab fa-github the-agunda-hover"></i>
                                    </a>
                                    <a href="https://join.skype.com/invite/yD5QuDPHxRZe" className="d-inline-block me-3" target={"_blank"}>
                                        <i className="fab fa-skype the-agunda-hover"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="col">
                                <MainHero></MainHero>
                            </div>
                            <div className="col">
                                <h4>Languages</h4>
                                <p>
                                    Nowadays, I write most of my code in either JavaScript or TypeScript.</p>
                                <h4>Web Development</h4>
                                <p>
                                    In the past, I run a indy web design agency. Thus, I have done a lot of HTML, CSS, Javascript, PHP in the past. I built both static and Wordpress sites.</p>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
        </>
    );
}

export default About;
