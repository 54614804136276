import Header from "../components/Header";
import { useEffect, useState } from "react";
import '../App.css';
import MainHero from "../assets/svg/MainHero";
import { Helmet } from "react-helmet";
function Skills() {
    const [skills, setSkills] = useState([]);
    useEffect(() => {
        fetch(`${window.location.origin}/assets/skills.json`)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then((response) => {
                setSkills(response);
            }).catch((error) => {
                console.log(error);
            });
    }, [skills])
    return (
        <>
            <Helmet>
                <title>My skills | Kiran Badola</title>
            </Helmet>
            <Header></Header>
            <main id="main-content" className='pt-2'>
                <section id="home">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-md-2 center-v">
                            <div className="col my-3">
                                <div className="title-badge d-inline-block mb-6 bg-primary text-primary bg-opacity-10 px-3 small">
                                    My Skills
                                </div>
                                <h1 className="mb-4">
                                    <span className="small">Hello World,</span><br />
                                    <span>I'm </span>
                                    <span className="text-primary the-agunda-hover">Kiran Badola</span>
                                </h1>
                                <h2 className="h3 fw-normal fs-normal text-body-light mb-3 the-agunda-hover">Full Stack Developer</h2>
                                <p className="text-body-light w-80 mb-2">
                                    As a developer I am constantly improving my <span className="text-primary the-agunda-hover">skills.</span>
                                </p>
                                <p className="text-body-light w-80 mb-6">
                                    I have a vast variety of skills in Front-end engineering, Backend Development, AWS & Deployment.
                                </p>
                            </div>
                            <div className="col d-flex justify-content-center">
                                <MainHero></MainHero>
                            </div>
                        </div>
                        <div className="row row-cols-1 row-cols-md-2 center-v mb-5 mt-3">
                            {
                                skills && skills.map((skill) => {
                                    return (
                                        <div className="skill mb-3" key={skill.id}>
                                            <h3 className="h6 the-agunda-hover">{skill.name}</h3>
                                            <div className="progress rounded-pill ">
                                                <div className="progress-bar bg-secondary rounded-pill" role="progressbar" aria-label="Basic example" style={{ width: skill.proficiency }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    <span className="ms-auto me-3 the-agunda-hover">{skill.proficiency}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Skills;
