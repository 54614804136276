import * as React from "react";
import style from "./MainHero.css";
const MainHero = (props) => (
    <svg
        id="illustration-hero"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        xmlSpace="preserve"
        viewBox="0 0 1263 1051.5"
        data-tor-group="          #dog      => mouseX:@parallax(50) ease-out slow;     #vase     => mouseX:@parallax(20) ease-out slow;     #table    => mouseX:@parallax(10) ease-out slow;     #woman    => mouseX:@parallax(5) ease-out slow;     #lamp     => mouseX:@parallax(5) ease-out slow;     #circle-interaction   => mouseX:@parallax(10) ease-out slow;          #floor      => inview:scaleX.from(0) slow;     #circle-reveal => inview:fade.in slow;     #vase_1_    => inview:scale.from(0) originY(bottom) quad slow delay(1s);     #dog_1_     => inview:[pull.left(xl) fade.in] quad slower delay(.6s);     #flowers-reveal => inview:scale.from(0) delay(1s) originY(bottom) slowest;     #table_1_   => inview:[pull.down(sm) fade.in] delay(.1s) slowest;     #lamp_1_    => inview:[pull.down(md) fade.in] delay(.2s) slow;     #woman_1_   => inview:[pull.left(md) fade.in] delay(.3s) slowest;     #blob path  => inview:[scale.from(0) rotate.from(-50deg)] delay(.3s) slowest;     .reveal     => wait(.3s);          #flowers      => loop:swing(3deg, {duration: 6s}) originX(50%) originY(100%);     #blob         => loop:spin({duration: 60s});     #head-loop    => loop:swing(5deg, {pause: 5s, speed: slowest}) originX(38%) originY(80%);     #leg          => loop:swing(5deg, {duration: 5s}) originX(66%) originY(9%);     #eyes         => loop:blink({pause: 3s});     #tail         => loop:swing(5deg, {speed: slower}) originX(60%) originY(100%);     #nose         => loop:float(-0.25rem, {pause: 2s, iterations: 2, speed: fastest});     #blob2        => loop:float({duration: 3s});     #circle-loop  => loop:float({speed: slowest});     #circle-spin  => loop:spin({duration: 15s}) originX(48%) originY(48%);     #screen       => loop:glow(2rem, {color: #fff, speed: slow});   "
        {...props}
    >
        <g id="blob">
            <path
                className="reveal"
                d="M581.8 946.9c50-5.5 186.5 5.7 223 13.6 36.5 7.9 157.6 25.2 215.3-20.9 57.7-46.1 21.1-207.6 30.8-261.5s121.5-131.5 123.3-230.1c1.7-98.6 1.7-210.1-125.2-256.3S777.9 239.8 689.5 209s-221.1-105.7-307.6-71.1S193.5 328 287.7 480s96.1 211.5 61.4 286.5-44.2 210.8 232.7 180.4z"
            />
        </g>
        <g id="woman" className="interaction">
            <g id="woman_1_" className="reveal">
                <g id="head-loop">
                    <path
                        className="st1"
                        d="M552.4 277.2c-14-2-46.1 13-47.1 42.8s25.5 39.3 29.8 41.4l61.6-9.6 4.3-68-48.6-6.6z"
                    />
                    <circle className="st1" cx={661.3} cy={155.4} r={47.9} />
                    <path
                        className="st2"
                        d="M630.2 188.3c2.9 1.4-21.7-57-34.2-47.9-11.8 8.6 19.4 41.1 34.2 47.9z"
                    />
                    <path
                        className="st2"
                        d="M631.6 191.9c2.7 3.2 65.4-18.8 56.7-31.4-8.3-12.2-59.4 28.2-56.7 31.4z"
                    />
                    <path
                        className="st3"
                        d="M674.1 261.9c-5.1.6-8.4 1.3-8.4 1.3.4-.1 3.6-.7 8.4-1.3z"
                    />
                    <path
                        className="st3"
                        d="M645.9 251.1c3.8 20.1-28.3 29.1-28.3 29.1s-.8 9.2.5 20c-15.2 10.2-30.7 16.5-30.7 16.5-.7.2-1.4.5-2.1.8l.1-2.2s-15 4-27.8-2.6c-14.1-7.3-17.1-22.8-12.5-45.6s1.1-54.8 14.8-71.9c5-6.3 13.3-11.8 23.2-15.2-9.9 3.4-18.1 8.9-23.1 15.2-5.6 7-8.3 16.4-9.9 26.7 9.1 1.5 22.5-1.6 22.5-1.6 18.3 20.5 34.6 23.8 34.6 23.8 12.6-12.8 35-12.3 38.7 7z"
                    />
                    <path
                        id="hair"
                        className="st1"
                        d="M729.9 278.2c-17.3-24.9-64.2-15-64.2-15 1.1-13.7 4.9-53.1-24.8-74.8s-67.3-10.3-81 6.8c-5.6 7-8.3 16.4-9.9 26.7 9.1 1.5 22.5-1.6 22.5-1.6 18.3 20.5 34.6 23.8 34.6 23.8 12.8-12.8 35.2-12.4 38.8 7 3.8 20.1-28.3 29.1-28.3 29.1s-.1 8.4-.2 12c-10.3 31.9 9.6 61.8 45.9 59.1 3.5 0 7-.3 10.7-.9 2.5.4 4.9.8 7.4 1.3 7.3 1.5 14.5 3.8 21.9 8.3 2.4 1.2 9.2-8.8 11.6-7.4 3.7-3.2 7-6.8 10-10.7 10.4-13.5 23-37.7 5-63.7z"
                    />
                    <path
                        d="M591.5 213c-6 5-13 8-21.1 10"
                        fill="none"
                        stroke="#eaa58b"
                        strokeWidth={4}
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                    />
                    <path className="st5" d="M585.1 317.6c4.7-1.6 9.1-3.6 13.4-6" />
                    <path className="st5" d="M618.8 260.1l9.1-5.7" />
                    <path className="st5" d="M566.5 286.8c1.9 1.2 9 2.1 11.8-3.4" />
                    <g id="eyes">
                        <circle className="st2" cx={585} cy={257.8} r={3.4} />
                        <circle className="st2" cx={556.7} cy={253.2} r={3.4} />
                    </g>
                    <path
                        className="st5"
                        d="M566.3 266.4c-.7 2.1-1.3 4.5-.9 5.3.5 1 1.3 1.8 2.3 2.3"
                    />
                    <circle className="st6" cx={623.1} cy={281.6} r={12} />
                    <ellipse
                        transform="rotate(-78.989 548.135 251.752)"
                        className="st7"
                        cx={548.1}
                        cy={251.8}
                        rx={19.7}
                        ry={17.7}
                    />
                    <ellipse
                        transform="rotate(-78.989 591.55 257.123)"
                        className="st7"
                        cx={591.6}
                        cy={257.1}
                        rx={19.7}
                        ry={17.7}
                    />
                    <path className="st7" d="M566.3 253.5l6.9.8" />
                    <path className="st7" d="M668 264.5l-2 12.8" />
                    <path
                        className="st8"
                        d="M665.7 263.2c1.1-13.7 4.8-53.1-24.9-74.8-9.4-6.9-19.6-10.4-29.6-11.5 10 1.1 20.3 4.6 29.7 11.5 29.7 21.6 25.9 61.1 24.8 74.8z"
                    />
                    <path
                        className="st8"
                        d="M737.1 318.7c3.4-11.9 2.9-25.9-7.2-40.5-7.5-10.8-20.6-15.1-33-16.4 12.4 1.3 25.6 5.6 33.1 16.4 10 14.6 10.5 28.6 7.1 40.5z"
                    />
                </g>
                <g id="leg">
                    <path
                        className="st9"
                        d="M511.1 698.7l-59 38.5C431 755.3 434 786.4 429 832.5c-3.8 35.7-28.1 67.2-28.1 67.2l-3.3-1.5-11.7-5.5-92.2-43c7.2-21.8 14.8-49.5 26-83.3 15-45.4 25.6-79.5 35.8-101.6 1.8-3.9 6.7-11.4 10.8-17l128.8 44.5 16 6.4z"
                    />
                    <path
                        className="st2"
                        d="M228 918c2.6-5.4 17.1-38.5 25.5-34.5 10.9 5.2-17.3 38.4-17.3 38.4L228 918z"
                    />
                    <path
                        className="st2"
                        d="M191.4 938.3l131.8 62.5c3.3 1.6 7.2.2 8.7-3.1 1.5-3.1.3-6.8-2.6-8.5l-2.8-1.6.3-.3-90.6-42.8c1.4-3.8 2.3-7.7 2.8-11.6 13.6 2.3 41 4.9 41.1-6.4.1-10.5-29.8-6.3-40.8-3.3-.2-4.1-1-8.1-2.3-11.9-30.2 2.1-40.5 12.7-45.6 27z"
                    />
                    <path
                        className="st10"
                        d="M415 684.1l-17.7 23.2-25.1-12.1 14.9 25.2-23.3 19.5 29.8-3.7 10.2 34.4 10.3-34.4 32.5-9.3-31.6-13.1z"
                    />
                    <path
                        className="st10"
                        d="M314.9 859.7l2.3-6.7 32-1.7-26-17.7 11.6-27.7-26.2 17.5-5-5.4c-4 13.2-7.5 24.9-11 35.2h.2c.4-1.1.8-2.3 1.1-3.4l21 9.9z"
                    />
                    <path
                        className="st3"
                        d="M314.9 859.7l-21-9.9c-.4 1.2-.8 2.3-1.1 3.4-12.7 37.8-24.3 56.7-51.7 57.9-1.4 0-2.6.1-3.9.2 1.3 3.8 2.1 7.8 2.3 11.9 11-3 40.9-7.2 40.8 3.3-.2 11.3-27.6 8.8-41.1 6.4-.5 4-1.5 7.9-2.9 11.6l90.6 42.8c12.3-15 35.8-46.5 59.1-94.1l-71.1-33.5z"
                    />
                    <path
                        className="st10"
                        d="M394.9 783.6l-3 27.1-28.1-1 24.1 18.1-9 32.1 24.1-21.1 21.7 14.4c.6-1.9 1.2-3.8 1.7-5.8l-8.3-20.7 12.9-14.1c.2-2.6.4-5.2.6-7.7l-22.5 3.8-14.2-25.1z"
                    />
                    <path
                        className="st10"
                        d="M332.4 727.3c-2.5 7.8-5.3 16.3-8.3 25.5l26.7 1.6-18.4-27.1z"
                    />
                </g>
                <g id="other">
                    <path
                        className="st9"
                        d="M697.8 577L511.1 698.7l-15.9-6.6L357.6 661c11.1-20 25.1-31.4 52.4-45.1 34.1-17 114.3-38.9 114.3-38.9h173.5z"
                    />
                    <path
                        className="st10"
                        d="M731.5 757.9H477.2l4.3-17.1h111.6l42.6-166.5c11.9-46.7 54-79.3 102.1-79.3 57.3 0 99.2 54 85 109.5l-26.3 102.9c-7.6 29.7-34.4 50.5-65 50.5z"
                    />
                    <path className="st1" d="M479.1 915.5h162.1v18.1H479.1z" />
                    <path
                        className="st1"
                        d="M667.9 998.8l-42.2-251H494.6l-42.2 251c-.8 4.5 2.3 8.8 6.8 9.6.5.1.9.1 1.4.1 3.9 0 7.3-2.7 8.1-6.6L524 747.8h72.4l55.2 254.1c.8 3.8 4.2 6.6 8.1 6.6 4.6 0 8.3-3.7 8.3-8.3 0-.5 0-1-.1-1.4z"
                    />
                    <path className="st11" d="M613.4 915.5h162.1v18.1H613.4z" />
                    <path
                        className="st11"
                        d="M802.2 998.8l-42.2-251H628.9l-42.2 251c-.8 4.5 2.3 8.8 6.8 9.6.5.1.9.1 1.4.1 3.9 0 7.3-2.7 8.1-6.6l55.2-254.1h72.4l55.2 254.1c.8 3.8 4.2 6.6 8.1 6.6 4.6 0 8.3-3.7 8.3-8.3.1-.5.1-1 0-1.4z"
                    />
                    <path
                        className="st10"
                        d="M669.7 707.4l16.1-62.9H579.2c-43 0-80.5 29.1-91.2 70.8l-10.9 42.6h127.5c30.8 0 57.5-20.8 65.1-50.5z"
                    />
                    <path className="st12" d="M484.1 739.2h135.3" />
                    <path className="st12" d="M593.6 738.5l23.7-92.7" />
                    <path className="st12" d="M811 642.3h-64" />
                    <path className="st12" d="M823 597.3h-82" />
                    <path
                        className="st10"
                        d="M410 615.9c-6.3 3.2-11.9 6.2-16.9 9.2l-7.7 24.6 29.6-20.8 27.3 21.8-10.1-29.8 30.9-14.2-23.9-3c-9.9 3.6-19.7 7.6-29.2 12.2z"
                    />
                    <path className="st10" d="M497.2 603.1l-2 13 13.1-5z" />
                    <path
                        className="st2"
                        d="M426.6 971.6c.4-6 1.7-42.1 11-41.5 12 .8-2 42.1-2 42.1l-9-.6z"
                    />
                    <path
                        className="st13"
                        d="M400.6 1008.4h150.5c-.4.1-.7.1-1.1.1H400.7s-.1 0-.1-.1z"
                    />
                    <path
                        className="st9"
                        d="M640.3 741h-89.9c6 28.1 16.1 35.1 27.1 76.2 10.5 39.2 1 83.2 1 83.2H459.2c-2-25.6-7.2-59.6-11.1-103.3-10-112.3-10.6-153.7 45.1-177 55.2-23.1 155.5-41.7 155.5-41.7H734s6.8 11.1 11.4 32C760.3 677.5 708.9 741 640.3 741z"
                    />
                    <path
                        className="st10"
                        d="M586.5 637.2l-18.4 23.2-28.7-9.9 17 27.8-22.1 30.1 29.1-12 16.2 29.9 1.9-32.9 28-6.1-24-15z"
                    />
                    <path
                        className="st10"
                        d="M546.4 816l17 27.8-22.1 30.1 29.1-12 10.8 19.9c1.6-15.4 2.4-38.3-2.7-60.2l-3.4 4.3-28.7-9.9z"
                    />
                    <path
                        className="st10"
                        d="M471.5 900.4l-1.3-15.5 32.6-12.6-30.8-6.4 3.4-33.8-20 26.2-.6-.3c1.9 16.2 3.6 30.3 4.5 42.5v-.1h12.2z"
                    />
                    <path
                        className="st3"
                        d="M550 995.7h-2.5c4.7-15.4 11.5-45.5 12.5-95.3H459.2v.1c.1 1 .2 2 .2 3v.3c2.1 31.3-1.3 49.5-19.4 57.7-30.3 13.8-40.9 31.1-39.4 46.9H550c3.5 0 6.4-2.9 6.4-6.4 0-3.5-2.9-6.4-6.4-6.3z"
                    />
                    <path
                        className="st10"
                        d="M683.7 581.1l-46 4.1 4.8 18.5-29.5 15.7 36.7 4.9 12.4 27.5 12.8-28.5 37.3-5.9-32.4-16.7z"
                    />
                    <path
                        className="st10"
                        d="M464.1 711.4l11-35.1 32.1 5-25.1-22.1 18-21.1-33.1 12-5.3-9.1c-8.5 9.6-13.7 21.6-16.4 37l5.6.2 13.2 33.2z"
                    />
                    <path
                        className="st10"
                        d="M550 601.3c-6.8 1.9-13.5 3.8-20.1 5.9l26.5 18-6.4-23.9z"
                    />
                    <path
                        className="st10"
                        d="M506.3 763.6l-14.1-29.1-7 32.1-26.1-2 24.1 21-15 35.1 26.1-24.3 21.6 24.9-6.6-37.7 22-29.1z"
                    />
                    <path
                        className="st2"
                        d="M446.7 984c13.3-3.7 39.1-13.1 34.5-23.4-4.4-9.6-29.7 7-38.3 14.4-2.2-4-5-7.6-8.3-10.8-26.2 13.3-35.4 29.5-34 44.3H550c3.5 0 6.4-2.9 6.4-6.4 0-3.5-2.9-6.4-6.4-6.4H449.1c-.4-4-1.2-7.9-2.4-11.7z"
                    />
                    <path className="st14" d="M550 743.2l-25.8-.4" />
                    <path
                        className="st14"
                        d="M569.2 596.9c-26.8 6.8-55.3 15.2-77 24.2-46.2 19.4-53.7 51.2-49.2 125.6"
                    />
                    <path
                        id="body"
                        className="st3"
                        d="M733.9 577l-35.1-62.2c6.1-55.1 16.1-162.5 16.1-162.5s.4-.3 1-.9h-.1c-.3.3-.7.6-1 1-9.3-5.7-19.5-9.7-30.1-12-12.1-2.7-25.3-1.1-37.3-3.5-6.2-1.1-12-3.7-16.9-7.6-10.1-8.2-13.1-37.1-13.1-37.1l-32.2 23-1.6 24.1s-29 1.1-46.8 7.5c-42.8 15.5-118 121.8-118 121.8-47.1-2-91.3-4-91.3-4l23.1 68.2s58.2 19 100.3 8c42.7-11.2 83.2-71.2 83.2-71.2l14 40.1-24.1 67.2h209.9v.1z"
                    />
                    <path
                        className="st15"
                        d="M647.5 336.9l-55 99.7-22.5-96.2c-9.9 1-23.1 2.9-32.8 6.5-43 15.5-118.2 121.8-118.2 121.8-47.1-2-91.3-4-91.3-4l23.1 68.2s58.2 19 100.3 8c42.7-11.2 83.2-71.2 83.2-71.2l14 40.1-24.1 67.2h209.6l-35.1-62.2c6-55.2 16-162.5 16-162.5-9.3-5.7-19.5-9.7-30.1-12-11.9-2.6-25.1-1-37.1-3.4z"
                    />
                    <path
                        id="screen"
                        className="st2"
                        d="M362.7 570h173.7l-39.5-124.7c-2.4-7.5-9.4-12.7-17.3-12.7H332.8c-10 0-18.1 8.1-18.1 18.1 0 1.9.3 3.7.9 5.5l32.6 103.1c1.9 6.4 7.8 10.7 14.5 10.7z"
                    />
                    <path
                        className="st1"
                        d="M388.5 489c4.6-6.3 25.5 10.3 27.1 11.7.8-1.4 11.7-18 19.7-11.7 6.9 5.5-8.3 29.8-12.3 36.1-.7.7-1.8.8-2.6.1l-.1-.1c-7.5-6.2-35.8-30.6-31.8-36.1z"
                    />
                    <ellipse
                        transform="rotate(-17.301 410.918 483.577)"
                        className="st1"
                        cx={410.9}
                        cy={483.6}
                        rx={3.3}
                        ry={10.1}
                    />
                    <path
                        className="st2"
                        d="M637.2 556c1.8 5.7-1.4 11.7-7.1 13.5-1 .3-2.1.5-3.2.5H362.7c-6.6 0-12.5-4.3-14.5-10.6l-1.1-3.4h290.1z"
                    />
                    <path
                        className="st10"
                        d="M796.5 707.4l16.1-62.9h-93.8c-43 0-80.5 29.1-91.2 70.8l-10.9 42.6h114.8c30.6 0 57.4-20.8 65-50.5z"
                    />
                    <path className="st12" d="M618.9 741.4l-3.7 15.7" />
                    <path
                        className="st3"
                        d="M319.4 468.3c-11.7 5.8-17.5 15.6-17.5 22.4 0 9.9 10.1 11.8 10.1 11.8s-7.5 11.9 5.6 17.4c0 0 0 12.2 12.2 12.2 0 0-1.6 9.5 11.1 10.4s25.9-5.8 27-10.1c1.3-5.1-2.2-9.3-11.4-10.7 0 0 12.5-5.8 10.5-13.2s-10.4-7.2-10.4-7.2 8.1-4.8 3.8-12-12.6-4.7-12.6-4.7 2.2-5-1.6-8.8c-4.5-4.5-23.8 1.2-23.8 1.2l-3-8.7z"
                    />
                    <path className="st5" d="M329.9 532.6c10.5-2.5 20.7-6.2 30.5-11" />
                    <path className="st5" d="M317.5 519.9c14.6-4 28.5-10.1 41.4-18.1" />
                    <path className="st5" d="M311 503.9c13.5-4 26.3-10.2 37.8-18.3" />
                    <path className="st16" d="M523.3 443.6l9.7 28.2" />
                    <path className="st16" d="M574.5 507.8H549" />
                    <path className="st16" d="M594.5 435.6l8 39.1v36.1" />
                    <path className="st16" d="M541.2 346l15.2 34.5-18.1-2 64.2 96.2" />
                    <path className="st16" d="M689.6 341.9l-25.9 36.6 24.1-2-88.3 78.2" />
                    <path className="st16" d="M703 464.5l-19.2-26.9" />
                    <path className="st16" d="M725.3 563.5c-10.5-3.5-29.3-10.2-40.6-17" />
                    <path
                        className="st3"
                        d="M684.8 481.2c-12.3-1.1-24.7-1.2-37.1-.4-17.7 1.5-41.5 6.1-51 10.7s-46.1 20.9-36.3 26.4c9 5 27.9-2.5 27.9-2.5s-18.8 17.3-22.1 24.6 7.5 11.3 7.5 11.3c2.8 12.4 15.4 7.1 15.4 7.1 8 16.9 50.9-19.7 50.9-19.7 10.2 1.1 20.5.6 30.6-1.3 0 0 5.4 3 14.2 7.3v-63.5z"
                    />
                    <path
                        className="st15"
                        d="M792.1 566c80.2-38.1-36.1-180.5-77.2-213.6l-23.1 93.3 29.1 40.1c-12.7-2.2-24.9-3.7-36.1-4.6v63.4c25.4 12.3 78.9 34.9 107.3 21.4z"
                    />
                    <path className="st5" d="M584.4 516.4c7.1-5.9 18.9-15.4 27.8-21.2" />
                    <path className="st5" d="M621.9 509.3c-20.5 13.3-39.2 31-48.4 40.3" />
                    <path className="st5" d="M631 524.3c-14 9.6-30.3 24.2-40.9 34.2" />
                </g>
            </g>
        </g>
        <g id="floor" className="reveal">
            <path
                id="floor_1_"
                fill="none"
                stroke="#df5646"
                strokeWidth={4}
                strokeLinecap="round"
                strokeMiterlimit={10}
                d="M37.2 1008.5h1182.3"
            />
        </g>
        <g id="table" className="interaction">
            <g id="table_1_" className="reveal">
                <path className="st11" d="M219.1 591.1h24.6v417.4h-24.6z" />
                <path className="st18" d="M243.7 596.8h-24.6" />
                <path className="st11" d="M884.1 591.1h24.6v417.4h-24.6z" />
                <path className="st18" d="M908.7 596.8h-24.6" />
                <path className="st11" d="M176.8 570H951v25.1H176.8z" />
                <g id="cup">
                    <path
                        className="st19"
                        d="M871.7 518.9c3.2 0 5.7 2.6 5.7 5.7v.9c0 3.2-2.6 5.7-5.7 5.7h-29.5v-12.3h29.5m0-8h-36.4c-.6 0-1.1.5-1.1 1.1v26.2c0 .6.5 1.1 1.1 1.1h36.4c7.6 0 13.7-6.1 13.7-13.7v-.9c0-7.7-6.1-13.8-13.7-13.8z"
                    />
                    <path
                        className="st19"
                        d="M827.7 506.6c-29.8 0-43.6-23.8-43.6-23.8l20 87.1h47.1l20-87.1c-9.4 15-25.9 23.9-43.5 23.8z"
                    />
                    <path
                        className="st2"
                        d="M827.7 506.6c17.6.1 34.1-8.8 43.6-23.8h-87.1c-.1.1 13.7 23.8 43.5 23.8z"
                    />
                </g>
            </g>
        </g>
        <g id="lamp" className="interaction">
            <g id="lamp_1_" className="reveal">
                <circle className="st10" cx={436.5} cy={295} r={19.7} />
                <path
                    className="st20"
                    d="M366.2 202.9l11.5 13.8c1.8 2.1 1.5 5.3-.7 7l-.2.2c-2.1 1.8-5.3 1.5-7-.7l-11.5-13.7c-1.8-2.1-1.5-5.3.7-7l.2-.2c2.1-1.8 5.2-1.5 7 .6z"
                />
                <path
                    className="st19"
                    d="M398.3 233.5l-11.5-13.9-207.1 171.7c-3.8 3.1-4.3 8.7-1.2 12.5l.2.3c3.1 3.8 8.7 4.3 12.4 1.2l64.7-53.7V540H274V336.5l124.3-103z"
                />
                <path
                    d="M364.9 237.5l5.8 7c2 2.4 3.5 5.2 4.5 8.2"
                    fill="none"
                    stroke="#4756df"
                    strokeWidth={4}
                    strokeMiterlimit={10}
                />
                <circle className="st20" cx={265} cy={332.1} r={23.6} />
                <circle className="st19" cx={265} cy={332.1} r={9.1} />
                <path className="st18" d="M452.6 283.8l-30.1 25" />
                <path
                    className="st19"
                    d="M413.5 231c-6.3-.7-12.1-3.9-16.2-8.8l-8-9.7c-5.9-7.1-16.4-8.1-23.5-2.2-7.1 5.9-8.1 16.4-2.2 23.5l8 9.7c4.1 4.9 6.1 11.2 5.6 17.5l-2 29.2c-1 14.3 2.6 28.6 10.4 40.6l2.6 4 95.9-79.6-3.4-3.2c-10.4-9.8-23.8-16-38-17.7l-29.2-3.3z"
                />
                <path
                    className="st19"
                    d="M274 539.9h-18.1l-26.7 7.3c-10.3 2.8-17.4 12.1-17.4 22.8h106.3c0-10.7-7.1-20-17.4-22.8l-26.7-7.3z"
                />
                <path className="st22" d="M465.9 241.2l-87.8 72.5" />
                <path className="st22" d="M255.5 537.9H274" />
                <path className="st22" d="M215.3 557.5H314" />
            </g>
        </g>
        <g id="dog" className="interaction">
            <g id="dog_1_" className="reveal">
                <path
                    id="tail"
                    className="st2"
                    d="M784.1 828c2-17 1-55.2-21.1-84.2s8-17 17-9c14.4 12.8 27.1 25.1 38.1 66.2l-34 27z"
                />
                <path
                    className="st1"
                    d="M1050.4 1008.5h117.7v-3.8c0-11.3-9.2-20.5-20.5-20.5h-97.9l.7 24.3z"
                />
                <path
                    className="st1"
                    d="M763.2 896.1l24.8 112.4h46.1v-7c0-9.4-7.6-17-17-17 0 0 23.1-17.3 37.1-34.3 0 0 24.1 29.3 58.2 24.3l2.5 12c2.6 12.8 13.9 22.1 27.1 22.1h111.8c49.6 0 74-7.6 75.4-35.8.2-4.4-3.3-8.1-7.7-8.3H1090c-2.6 0-5.1-1.2-6.7-3.2l-13.3-16.5c-8-10-21.2-14.3-33.6-11.2l-26.7 6.8s-47.4-68.1-90.3-115.3c-41-45.1-118.1-50-148.5 8.8-9.7 19.1-12.4 41.2-7.7 62.2z"
                />
                <path
                    d="M1042.8 1008.5v-3.8c0-11.3-9.2-20.5-20.5-20.5h0-13.6"
                    fill="none"
                    stroke="#30242a"
                    strokeWidth={4}
                    strokeMiterlimit={10}
                />
                <path
                    className="st7"
                    d="M856.2 950.9c7-8.3 30.9-39 29.2-69-1.8-32.1-21.1-49.1-21.1-49.1"
                />
                <path className="st7" d="M906.4 932s1.3 23.7 3.8 41.5" />
                <path className="st7" d="M810.1 998.2v9.3" />
                <path className="st7" d="M821.2 998.2v9.3" />
                <path className="st7" d="M1020.7 998.2v9.3" />
                <path className="st7" d="M1031.8 998.2v9.3" />
                <circle className="st2" cx={1061.3} cy={966.6} r={4.5} />
                <path className="st2" d="M1036.7 933.4l-27.1 6.9 26.7-6.8.4-.1z" />
                <path
                    className="st2"
                    d="M970.1 984.6c-7.8 4-18.9 11.8-18 23.7h43.5l9.2-14.4c3-4.8 8.3-7.7 14-7.7h2.9l14.9-52.5-.2-.2-26.7 6.8-25.6 32.8c-3.8 4.8-8.5 8.7-14 11.5z"
                />
                <path
                    className="st24"
                    d="M906.5 849.5s-12.1 41.9 27 57.8c19.8 8 36.3 1.9 47.7-6-17.3-23-40.1-52.3-61.8-76.2-5.4-6-11.6-11.3-18.2-15.9-5.9 8-12.4 27.9 5.3 40.3z"
                />
                <ellipse
                    transform="rotate(-80.781 806.605 863.362)"
                    className="st24"
                    cx={806.6}
                    cy={863.4}
                    rx={16.5}
                    ry={16.6}
                />
                <path
                    id="nose"
                    className="st2"
                    d="M1121.3 964.4h-6c-1.2 6.3 2.8 12.3 9.1 13.6.7.1 1.5.2 2.2.2s1.4-.1 2.1-.2c.3-1.7.5-3.5.6-5.3.2-4.4-3.3-8.1-7.7-8.3h-.3z"
                />
                <path className="st7" d="M1145.1 998.2v9.1" />
                <path className="st7" d="M1156.1 998.2v9.1" />
                <path className="st16" d="M1084.6 1007.3c24.5-2.5 39.1-9.1 44.4-23.2" />
            </g>
        </g>
        <g id="vase" className="interaction">
            <g id="vase_1_" className="reveal">
                <path
                    className="st20"
                    d="M107.7 924.7c6.6-19 27.4-29 46.4-22.4 10.5 3.7 18.7 11.9 22.4 22.4 4.9-13.9 17.6-23.6 32.4-24.4-3.2-5.7-7-11.1-11.4-16l-13.4-14.8c-8.4-9.5-10-23.2-4-34.3l11.2-20.8c-3.1 3.2-21.4 20.2-49 20.2s-45.9-17-49-20.2l11.2 20.8c6 11.2 4.3 24.9-4.1 34.3l-13.2 14.8c-4.4 4.9-8.2 10.3-11.5 16 14.6 1 27.2 10.6 32 24.4z"
                />
                <path
                    className="st20"
                    d="M210.8 945.8c-15.5 0-29.3 9.8-34.4 24.4-6.6-19-27.3-29-46.3-22.4-10.5 3.7-18.8 11.9-22.4 22.4-6.2-17.9-25.1-28-43.5-23.3.5 23.1 9.8 45.1 26 61.5h104.2c16.2-16.4 25.5-38.4 26-61.4-3.1-.8-6.4-1.2-9.6-1.2z"
                />
                <path
                    className="st2"
                    d="M142.3 834.6c27.6 0 45.9-17 49-20.2h-98c3.1 3.1 21.3 20.2 49 20.2z"
                />
                <path
                    className="st19"
                    d="M107.7 970.3c6.6-19 27.3-29 46.3-22.4 10.5 3.7 18.8 11.9 22.4 22.4 6.3-18.1 25.5-28.2 44-23.2.4-16.3-3.6-32.5-11.6-46.7-14.7.8-27.5 10.4-32.4 24.4-6.6-19-27.4-29-46.4-22.4-10.5 3.7-18.7 11.9-22.4 22.4-4.8-13.8-17.3-23.3-31.9-24.3-8 14.2-12 30.3-11.6 46.6 18.4-4.9 37.3 5.3 43.6 23.2z"
                />
                <g id="flowers-reveal" className="reveal">
                    <g id="flowers">
                        <path className="st7" d="M149.5 831.7c2-12.2 11.1-60.8 34-87.1" />
                        <path className="st7" d="M139 832.1c-.1-13.9-1.6-82.2-15.1-122.9" />
                        <ellipse
                            transform="rotate(-23.748 195.54 776.113)"
                            className="st2"
                            cx={195.5}
                            cy={776.1}
                            rx={39.6}
                            ry={8.9}
                        />
                        <ellipse
                            transform="rotate(-53.511 193.018 655.66)"
                            className="st2"
                            cx={193}
                            cy={655.7}
                            rx={21.9}
                            ry={7.7}
                        />
                        <ellipse
                            transform="rotate(-8.598 89.378 782.906)"
                            className="st2"
                            cx={89.4}
                            cy={782.9}
                            rx={47.9}
                            ry={8.5}
                        />
                        <path
                            id="flower1"
                            className="st13"
                            d="M72.4 723.1c8.5 4.8 11.6 15.6 6.8 24.1-4.2 7.5-13.1 10.9-21.2 8.1v.1c21.9 9.8 42.9 10.6 46.9 1.7s-10.6-24.2-32.5-34z"
                        />
                        <path
                            id="flower2"
                            className="st13"
                            d="M137.1 651.8c-4.7-1.2-10.7 5.8-15.1 16.8-5.3-13.3-13.1-22.1-18.3-20.4s-6.1 13.4-2.5 27.3c-10.1-6.1-19.1-8.2-22.1-4.4-3.9 4.8 3.6 17.2 16.6 27.7 3.7 3 7.7 5.7 11.9 7.9 2.4-9.5 12-15.2 21.5-12.8 2.4.6 4.6 1.7 6.6 3.2 2-4.2 3.5-8.6 4.7-13.1 4.1-16.3 2.7-30.7-3.3-32.2z"
                        />
                        <path
                            id="flower3"
                            className="st13"
                            d="M238.8 725.4c-1.4-4.6-10.6-6-22.2-4 8.4-11.5 11.9-22.8 7.6-26.3s-14.7 1.9-24.5 12.3c-.1-11.8-3.2-20.6-8-21.2-6.1-.8-12.7 12.1-14.7 28.8-.6 4.8-.8 9.6-.4 14.3 9.3-3 19.3 2.1 22.3 11.4.8 2.4 1 4.9.7 7.3 4.6-.6 9.1-1.6 13.6-3 15.9-5 27.4-13.8 25.6-19.6z"
                        />
                        <path
                            className="st2"
                            d="M193.3 732.5c-4.6-3.9-11-5.1-16.7-3.3.6 7.9 2.6 14 5.6 16.2 2.8 2.6 9.3 3.5 17.4 2.5.6-5.8-1.7-11.6-6.3-15.4z"
                        />
                        <path
                            className="st2"
                            d="M119.1 694.1c-5.7 1.9-10.1 6.6-11.6 12.5 7 3.7 13.2 5.2 16.7 3.8 3.7-1 7.9-6.1 11.3-13.4-4.6-3.7-10.7-4.7-16.4-2.9z"
                        />
                        <path
                            className="st6"
                            d="M74.3 752.8c-4.1-2.3-5.6-7.5-3.3-11.7 2-3.7 6.4-5.3 10.4-3.9-.5-5.9-3.9-11.1-9-14l-14.5 32.1c5.5 1.9 11.7 1 16.4-2.5z"
                        />
                        <path
                            d="M70.6 741.7c-1.8 4.1-.2 8.8 3.7 11 4.9-3.6 7.6-9.5 7-15.6-4.2-1.5-8.8.5-10.7 4.6z"
                            fill="#4856df"
                        />
                    </g>
                </g>
            </g>
        </g>
        <g id="top">
            <g id="circle-reveal" className="reveal">
                <g id="circle-interaction">
                    <circle id="circle-loop" cx={441.1} cy={91.4} r={64}  />
                </g>
            </g>
            <path
                id="blob2"
                d="M837.1 117.1c4.4 6.3 1.4 13.3-4.6 16.3-7 4-15 3-22-2-9-7-16-23-4-29 8-4 14 9 24 10.4 2.5 1.2 4.8 2.6 6.6 4.3z"
                fill="#78add9"
            />
            <g id="circle-spin">
                <circle className="st28" cx={488.1} cy={52.4} r={24.1} />
                <circle cx={441.1} cy={91.4} r={64} fill="none" />
            </g>
            <circle className="st28" cx={832.2} cy={107.5} r={10.3} />
        </g>
    </svg>
);

export default MainHero;
