
import React from "react";


class ImageCard extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <img  {...this.props} />
        )
    }
}

export default ImageCard;