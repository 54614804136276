
import React from "react";
class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            technologies: this.props.technologies ?? []
        }
    }
    handleClick = () => {
        this.props.onClick(this.props.data);
    }
    render() {
        return (
            <div className="card project-card" onClick={this.handleClick}>
                <img className="card-img-top" src={this.props.image ? this.props.image : ''} alt="Card image cap" />
                <div className="card-body">
                    <div className="content">
                        <h5 className="card-title">
                            {this.props.name}
                        </h5>
                        <p className="card-text">
                            {this.props.description}
                        </p>
                        <div className="d-flex flex-wrap">
                            {
                                this.props.technologies && this.props.technologies.map((data) => {
                                    return (<div className="pills" key={data}>{data}</div>);
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Card;